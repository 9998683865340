/*!
 *
 *  Web Starter Kit
 *  Copyright 2015 Google Inc. All rights reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License
 *
 */
/* eslint-env browser */


(function() {
  'use strict';

  // Check to make sure service workers are supported in the current browser,
  // and that the current page is accessed from a secure origin. Using a
  // service worker from an insecure origin will trigger JS console errors. See
  // http://www.chromium.org/Home/chromium-security/prefer-secure-origins-for-powerful-new-features
  var isLocalhost = Boolean(window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
    );

  if ('serviceWorker' in navigator &&
      (window.location.protocol === 'https:' || isLocalhost)) {
    navigator.serviceWorker.register('service-worker.js')
    .then(function(registration) {
      // updatefound is fired if service-worker.js changes.
      registration.onupdatefound = function() {
        // updatefound is also fired the very first time the SW is installed,
        // and there's no need to prompt for a reload at that point.
        // So check here to see if the page is already controlled,
        // i.e. whether there's an existing service worker.
        if (navigator.serviceWorker.controller) {
          // The updatefound event implies that registration.installing is set:
          // https://slightlyoff.github.io/ServiceWorker/spec/service_worker/index.html#service-worker-container-updatefound-event
          var installingWorker = registration.installing;

          installingWorker.onstatechange = function() {
            switch (installingWorker.state) {
              case 'installed':
                // At this point, the old content will have been purged and the
                // fresh content will have been added to the cache.
                // It's the perfect time to display a "New content is
                // available; please refresh." message in the page's interface.
                break;

              case 'redundant':
                throw new Error('The installing ' +
                                'service worker became redundant.');

              default:
                // Ignore
            }
          };
        }
      };
    }).catch(function(e) {
      console.error('Error during service worker registration:', e);
    });
  }

  // Your custom JavaScript goes here


  /* color gradient animation */
  var colors = new Array(
    // [7,206,107],
    // [28, 181, 105],
    [241, 157, 50],
    [116, 89, 167],
    [244, 87, 138],
    [157, 88, 158],
    [242, 125, 90],
    [40, 90, 184]);

  var step = 0;
  //color table indices for:
  // current color left
  // next color left
  // current color right
  // next color right
  var colorIndices = [0,1,2,3];

  //transition speed
  var gradientSpeed = 0.001;

  function updateGradient()
  {
    var c0_0 = colors[colorIndices[0]];
    var c0_1 = colors[colorIndices[1]];
    var c1_0 = colors[colorIndices[2]];
    var c1_1 = colors[colorIndices[3]];

    var istep = 1 - step;
    var r1 = Math.round(istep * c0_0[0] + step * c0_1[0]);
    var g1 = Math.round(istep * c0_0[1] + step * c0_1[1]);
    var b1 = Math.round(istep * c0_0[2] + step * c0_1[2]);
    var color1 = "#"+((r1 << 16) | (g1 << 8) | b1).toString(16);

    var r2 = Math.round(istep * c1_0[0] + step * c1_1[0]);
    var g2 = Math.round(istep * c1_0[1] + step * c1_1[1]);
    var b2 = Math.round(istep * c1_0[2] + step * c1_1[2]);
    var color2 = "#"+((r2 << 16) | (g2 << 8) | b2).toString(16);

    $('.section-gradient').add('.bg-color-gradient').css({
    background: "linear-gradient(30deg, "+color1+" 0%, "+color2+" 100%)"}).css({
    background: "-webkit-linear-gradient(30deg, "+color1+" 0%, "+color2+" 100%)"}).css({
    background: "-moz-linear-gradient(30deg, "+color1+" 0%, "+color2+" 100%)"
    });

    step += gradientSpeed;
    if ( step >= 1 )
    {
      step %= 1;
      colorIndices[0] = colorIndices[1];
      colorIndices[2] = colorIndices[3];

      //pick two new target color indices
      //do not pick the same as the current one
      colorIndices[1] = ( colorIndices[1] + Math.floor( 1 + Math.random() * (colors.length - 1))) % colors.length;
      colorIndices[3] = ( colorIndices[3] + Math.floor( 1 + Math.random() * (colors.length - 1))) % colors.length;

    }
  }
  //setInterval(updateGradient,10);
  
  $(document).ready(function(){
    setTimeout(function(){
      $('.number-contact').niceSelect();
    },200);

    $('.testi-slider').slick({
      arrows: true,
      dots: false,
      autoplay: true,
      autoplaySpeed: 2000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: '<button class="prev"><i class="fa fa-long-arrow-left"></i></button>',
      nextArrow: '<button class="next"><i class="fa fa-long-arrow-right"></i></button>'
    });

  });

  $(document).on('click', '.menu li a[data-dropdown]', function(e){
    var dataDropdown = 'service';
    if(dataDropdown){
      e.preventDefault();
      $('.headermenu').addClass('opened');
      // $('.custom-dropdowns').fadeOut();
      // $('.custom-dropdowns#'+dataDropdown).fadeIn();
      $('.custom-dropdowns').removeClass('opened');
      $('.custom-dropdowns#'+dataDropdown).addClass('opened');
      $('.bg-backdrop').addClass('show');

    }
  });

  $(document).on('click', '.menu li a[ui-sref]', function(e){
    $('.bg-backdrop').removeClass('show');
    $('.custom-dropdowns').removeClass('opened');
  });

  $(document).on('click', '.bg-backdrop, .custom-dropdowns .closed', function(e){
    e.preventDefault();
    $('.headermenu').removeClass('opened');
    $('.bg-backdrop').removeClass('show');
    $('.custom-dropdowns').removeClass('opened');
  });

  //Dialogs
    $('.dialog-overlay').click(function(){
        $(this).parent('.dialog-wrap').addClass('dialog-close').removeClass('dialog-open');
        $('body').removeClass('dialog-open');
    });
    $('.dialog-close').click(function(){
        $(this).closest('.dialog-wrap').addClass('dialog-close').removeClass('dialog-open');
        $('body').removeClass('dialog-open');
    });
    $('.dialog-show-btn').click(function(){
        var dialog_id = $(this).attr('dialog-show');
        $(dialog_id).addClass('dialog-open').removeClass('dialog-close');
    });
    $('.input-select').focus(function(){
        var dialog_id = $(this).attr('dialog-show');
        $(dialog_id).addClass('dialog-open').removeClass('dialog-close');
    });

    $('.box-options li').click(function(){
      $(this).addClass('active');
      $('.box-options li').not(this).removeClass('active');
      $('#service_type').val($(this).attr('data-name'));
    });

    $('.upload-nav').click(function(){
      var toShow = $(this).attr('data-show');
      $('.upload-ops').removeClass('active');
      $('#'+toShow).addClass('active');
    });
})();

